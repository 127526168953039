import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Updating MacOS Terminal Color Scheme",
  "component": "none",
  "date": "2018-08-08T13:35:13.234Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ff93e5ca54cf23681ddabb24f30f6eba/a8979/terminal.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "87.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAABYlAAAWJQFJUiTwAAADjklEQVQ4y6WTSW/bVhCA3WsbN41s1421WEskSpQoLuImipskmxK1hN7qJbaSoCiQQ+Jb66Dof+qp115qtinsHnoomkh2/0hCcjKkKsNC2lMf8OGNRtCHmTejhfFPP8iXf/y4++avq4fX4yvn7+srZzweO9eTsfPrnxPn4vJ357dXr5xfXNd58/q1M8F8+P3kH6J4Mtm5vLiILYTneHRw/uLlV/D1s1N4/OQ5fDl6CttHJ7B9eAL9fWT3APo7+xFbB8dRfuvw+Abn4BHsnTwFY7PbiYRZjjqn2g0gGtI7gqV8qpb176xm/Vgy48cSSDLr3wvvBN7xzE18i3dLqRwsrtwfRsKSwJ7Thga0rnl5jg9YkQokjQkyZTHI0wLetaDEyQHBSogY5CpckCQqt/FSpSqsJNJTIcHTbrVtQtVUPco0QOxqoA3rQCsqiJoIjExDqsgA/jAiUahAPE/OCOL5spckKFiOr0+Fgia6vd0OGHbfs5wOHD1uwuZAga09GeRmC9GB5FVYxypCWaJQhmRhJi9/KBQ1ynV2qnD6QvNMewtM20GJDWpLgbKgAaO0gGs0gazVochKkK/y0V1gxFCKQnJeWKuX3aoowPcvOa/V3wbR6IBkWsDrHcjTPKw9KCHTFsPqZmBlIR9WWFVIt97ngWuLHikpQDA8ikR8Nwru54qRMEmU5sRT/kN4dFxxn5/2wOg/8Q5HfTga9aBl97BNFdmAxkYHukMFMmUG35GGRP6mun8XUhLtskYbaprhKYYCFaEBgsqCoClAiTqU8O3KvAIFWoBshZ1rOxwKMi9Ms6Sb1wUQLdZj1HA9aFjNFBECycMXWeKG2ROErU/vUoCxF0f50lpqKuQNzlUHNsh2x2NbZjRBsqZElVVEFXChocjJWK2KORmnW4emxUKtgVPnlABzXhm7SuSIqVDSNdd6uA+65Xj1ph0Iejc4HHUC2WwFgtEJlHYvkJvdoN6yI5SNPn62As0azvJeY3MAaaI8FdJy+2de74LStt9KZscPUTdtXzQtX7WGGA98Qd/06brhU5Lm07IREcaMYob5t1VZh0yxMoiEjEpdGNsqVPEfQakNbFnGVuqQxqmmSQany0bTnU12LVyZaA9nq4P7iUPBN3SmFTKxPUFIfpfIkt+uPyicpbJIrnC2GFs5++SzpYg795bPPl36/BYrc/Hd5dVvPl68m1u4dT76n0Sn9ejZwntUdvn4DrWLZwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Terminal",
            "title": "Terminal",
            "src": "/static/ff93e5ca54cf23681ddabb24f30f6eba/0a47e/terminal.png",
            "srcSet": ["/static/ff93e5ca54cf23681ddabb24f30f6eba/8a4e8/terminal.png 150w", "/static/ff93e5ca54cf23681ddabb24f30f6eba/5a46d/terminal.png 300w", "/static/ff93e5ca54cf23681ddabb24f30f6eba/0a47e/terminal.png 600w", "/static/ff93e5ca54cf23681ddabb24f30f6eba/1cfc2/terminal.png 900w", "/static/ff93e5ca54cf23681ddabb24f30f6eba/c1b63/terminal.png 1200w", "/static/ff93e5ca54cf23681ddabb24f30f6eba/a8979/terminal.png 1828w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Spice up the default color scheme of the MacOS terminal profiles with a set of pretty iTerm 2 color schemes.`}</p>
    <p>{`To start, `}<a parentName="p" {...{
        "href": "https://github.com/lysyi3m/macos-terminal-themes/archive/master.zip"
      }}>{`download an archive`}</a>{` of color schemes from `}<a parentName="p" {...{
        "href": "https://github.com/lysyi3m/macos-terminal-themes"
      }}>{`lysyi3m’s Github repository`}</a>{`. Locate the downloaded `}<inlineCode parentName="p">{`macos-terminal-themes-master.zip`}</inlineCode>{` file and unarchive it to a local folder. Navigate to the `}<inlineCode parentName="p">{`../osx-terminal-themes-master/schemes`}</inlineCode>{` directory.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8e4fbbe495940e422b09a3afc9324e01/7be33/terminal-profiles.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAABYlAAAWJQFJUiTwAAADWUlEQVQ4y6WUyW7bVhSG1b5FF0UfoChgyy4N5CWSR+mu+y666LIIUHRiusgAo+iqKIImcSxosExSiiQOkjiKIqnJjibahm1Run/PZWI1ru1VL/DjitLld8/wH2UytIznf31q6vq9xWIpBH5XiMK+4FuGEPtNoWVaQrVSESoky7IE0zRviL6/1263P8tcrWa5/DCKQuz+vntZVxusWlOY6gTsb9VnvucyXTdIOvM8j7mue02O4yzG4zFqtdova+CLvT3RdX2I4qMkX8pjP7+HklzH82IdHacNyzJBkYCiuKFWq5UMh0Ps7+8/WQMl6VBUGq/Qto3FZDxls9mcHR+NWNTtsN3ykNWsPhv2Q9bvD0h9FgRBuodhyKIoWlxeXqJer38AVCTx+6ci6oae0K9YLpeYz+c4KJeRK0poaAZ830en06FoLTSbTRiGkcq27YSfbTQaHwDlQzF3IKHZMpNer4cLgk6nUzRUFR3Phes46Ha7KZRqBqpdCuaiuiZxHF+PsFpRxJPYRhC4yejoLc4vLjCeTKCqDZKa1o8DKUVQiimYX8w/U/rJ2dnZf4EVseOYOB72EoCBr/lsBt1o8pRSAIfRywQOKGJ3fQHtyenp6XXgoVwRf3gxxp8lJzGDI7jDE/SGb5GreSgUi5BliaeWAnwC1TUNsiSlF/EIbwBfFyTxq59fYuebN8nWt1188rWDR7kojaasVFGtvIGm6XBcK4WavIbvy3ArsFSWxO9+fIzdl9VE6ZzhlT6GHR5DOSyhnMvBdnVYro2m6sKwnfeN8e5OuaLI4sEfTxC2tQSrC/A6nsQzKJSWRik3WnmylAop38aB3kpto1HavCl3ABWxXiiiqemJH4SYzuaYUVPkaoNeblFH026+awqJ+5HrzgglWf71dVmC7XmL6WTCyFdsOp2westjsqLweU2ng4sAzDQtRh5MJ4WeFyfvfPj43wir1d9UvwsvCFYzMvRytQL31qDf4wfTmR2NRhgMBuBzeyX+HPV6q8Vyxf36bA0sFAoP+2RU27ImZOKYiyKIqVaxqmkx/QHEBE33WzTlDSoViz9d8T7a2Nj4fDObfUD7/Ww2u9b29vb9nZ2ddN/a2rpV/JwgfPlgc3PzC87KnJ+fr8Gkj/+HMmEYZf4BAWr4aJeTqqsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Preferences",
            "title": "Preferences",
            "src": "/static/8e4fbbe495940e422b09a3afc9324e01/0a47e/terminal-profiles.png",
            "srcSet": ["/static/8e4fbbe495940e422b09a3afc9324e01/8a4e8/terminal-profiles.png 150w", "/static/8e4fbbe495940e422b09a3afc9324e01/5a46d/terminal-profiles.png 300w", "/static/8e4fbbe495940e422b09a3afc9324e01/0a47e/terminal-profiles.png 600w", "/static/8e4fbbe495940e422b09a3afc9324e01/1cfc2/terminal-profiles.png 900w", "/static/8e4fbbe495940e422b09a3afc9324e01/c1b63/terminal-profiles.png 1200w", "/static/8e4fbbe495940e422b09a3afc9324e01/7be33/terminal-profiles.png 1558w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Open the `}<inlineCode parentName="p">{`terminal.app`}</inlineCode>{` and press the `}<inlineCode parentName="p">{`Cmd + ,`}</inlineCode>{` keys to view terminal preferences. Choose the profiles menu. From there select the gear icon (bottom left) and import the desired scheme from the `}<inlineCode parentName="p">{`schemes`}</inlineCode>{` folder, file type `}<inlineCode parentName="p">{`.terminal`}</inlineCode>{`. `}</p>
    <p>{`Once imported the color scheme is added to the profile list. Select the newly imported profile from the profile list. Adjust the other profile settings, such as font size, if desired. Finally, press the `}<inlineCode parentName="p">{`default`}</inlineCode>{` button to set the profile as the default.`}</p>
    <p>{`Color formatting may need to be enabled. To enable for bash shell. Copy the below lines to the `}<inlineCode parentName="p">{`~/.bash_profile`}</inlineCode>{` and restart the terminal.`}</p>
    <pre {...{
      "className": "grvsc-container dark-default-dark",
      "data-language": "bash",
      "data-index": "0"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk4"
            }}>{`export`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` PS1=`}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`"\\[\\033[36m\\]\\u\\[\\033[m\\]@\\[\\033[32m\\]\\h:\\[\\033[33;1m\\]\\w\\[\\033[m\\]`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`\\$`}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{` "`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk4"
            }}>{`export`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` CLICOLOR=1`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk4"
            }}>{`export`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` LSCOLORS=ExFxBxDxCxegedabagacad`}</span></span></span></code></pre>
    <p>{`No actual productivity improvements, but it may feel like it.`}</p>
    <div className="gap-20"></div>
    <blockquote>
      <p parentName="blockquote">{`References:`}</p>
      <ul parentName="blockquote">
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://github.com/lysyi3m/macos-terminal-themes"
          }}>{`macos-terminal-themes`}</a></li>
      </ul>
    </blockquote>

    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
    line-height: 1.4;
  }
  
  .grvsc-code {
    display: table;
  }
  
  .grvsc-line {
    display: table-row;
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }
  
  .grvsc-line > * {
    position: relative;
  }
  
  .grvsc-gutter-pad {
    display: table-cell;
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  .grvsc-gutter {
    display: table-cell;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter::before {
    content: attr(data-content);
  }
  
  .grvsc-source {
    display: table-cell;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-source:empty::after {
    content: ' ';
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter + .grvsc-source {
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  /* Line transformer styles */
  
  .grvsc-has-line-highlighting > .grvsc-code > .grvsc-line::before {
    content: ' ';
    position: absolute;
    width: 100%;
  }
  
  .grvsc-line-diff-add::before {
    background-color: var(--grvsc-line-diff-add-background-color, rgba(0, 255, 60, 0.2));
  }
  
  .grvsc-line-diff-del::before {
    background-color: var(--grvsc-line-diff-del-background-color, rgba(255, 0, 20, 0.2));
  }
  
  .grvsc-line-number {
    padding: 0 2px;
    text-align: right;
    opacity: 0.7;
  }
  
  .dark-default-dark {
    background-color: #1E1E1E;
    color: #D4D4D4;
  }
  .dark-default-dark .mtk4 { color: #569CD6; }
  .dark-default-dark .mtk1 { color: #D4D4D4; }
  .dark-default-dark .mtk8 { color: #CE9178; }
  .dark-default-dark .mtk6 { color: #D7BA7D; }
  .dark-default-dark .grvsc-line-highlighted::before {
    background-color: var(--grvsc-line-highlighted-background-color, rgba(255, 255, 255, 0.1));
    box-shadow: inset var(--grvsc-line-highlighted-border-width, 4px) 0 0 0 var(--grvsc-line-highlighted-border-color, rgba(255, 255, 255, 0.5));
  }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      